@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/*====================================================================

index.css

=====================================================================*/

@use "_plugins/slick"; //slick

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	position: relative;
	&__inner {
		display: flex;
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
	&__slider {
		position: relative;
		@include media-breakpoint-up(lg) {
			width: 60%;
		}
		@include media-breakpoint-up(xl) {
			width: 65%;
		}
		&__list {
			position: relative;
		}
		.swiper-slide {
			margin: 0;
		}
		&__img {
			@include aspect-img;
		}
		.swiper-pagination {
			position: absolute;
			bottom: 10px;
			display: block;
		}
		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			background: $dl-gray;
			opacity: 1;
			@include media-breakpoint-up(md) {
				width: 10px;
				height: 10px;
			}
			&-active {
				background: $main_c;
			}
		}
	}
	&__txt {
		background: url(../img/top/top_bg_tb.jpg) top left/cover no-repeat;
		@include media-breakpoint-up(lg) {
			width: 40%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: url(../img/top/top_bg_pc.jpg) top left/cover no-repeat;
		}
		@include media-breakpoint-up(xl) {
			width: 35%;
		}
		&__inner {
			padding: clamp(2rem, 3vw, 3rem) 5%;
			@include media-breakpoint-up(md) {
				padding: clamp(3rem, 4vw, 4rem) 15% clamp(3rem, 5vw, 5rem);
			}
			@include media-breakpoint-up(xl) {
				padding: 1em 10%;
				max-width: 600px;
			}
		}
		&__tit {
			margin-bottom: clamp(1.5rem, 3vw, 3rem);
			b {
				display: block;
				@include f-family(font02);
				@include l-sp(0.05em);
				@include f-w(400);
				@include line-h(1.2);
				@include f-s_xxs(2, 36);
				color: $other_c1;
				@include media-breakpoint-up(md) {
					@include f-s_md(3, 22);
				}
				@include media-breakpoint-up(lg) {
					@include f-s_lg(1.75, 16);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(50);
				}
			}
			span {
				@include f-s_xxs(1.25, 8);
				@include l-sp(0.5em);
				color: $txt_c;
				@include f-w(600);
				@include media-breakpoint-up(md) {
					@include f-s_md(1.75, 8);
				}
				@include media-breakpoint-up(lg) {
					@include f-s_lg(1.25, 10);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(34);
				}
			}
		}
		&__txt {
			@include line-h(2);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(0.9, 2);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(18);
			}
		}
	}
}

/* common
----------------------------------------------------------------*/

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/

//top works
.top-works {
	padding: clamp(4rem, 7vw, 7rem) 0 clamp(4rem, 7vw, 7rem);
	&__inner {
		.btn-wrap {
			margin-top: clamp(2rem, 4vw, 4rem);
		}
	}
	&__list {
	}
}
.top-works__box {
	a {
		display: block;
		height: 100%;
		@include dec-none;
		color: $txt_c;
		@include transition;
		&:hover {
			box-shadow: 0 0 10px rgba($black, 10%);
			.top-works__box__img__txt {
				bottom: 0;
			}
			.top-works__box__txt {
				&::after {
					height: 100%;
				}
			}
		}
	}
	&__inner {
	}
}
.top-works__box {
	&__img {
		overflow: hidden;
		position: relative;
		z-index: z-index(module, part04);
		&__txt {
			position: absolute;
			left: 0;
			bottom: calc(100% + 2px);
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;
			text-align: center;
			@include transition;
			background: rgba($black, 0.5);
			z-index: z-index(module, part02);
			@include f-w(600);
			@include line-h(1.75);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(0.8, 2);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(14);
			}
		}
		&__inner {
			margin: 0;
			@include aspect-img;
		}
	}
}
.top-works__box {
	&__txt {
		padding: 1em 5% 2em 2.25em;
		position: relative;
		z-index: z-index(module, part03);
		&::before {
			position: absolute;
			left: 1.25em;
			top: -1em;
			z-index: z-index(module, part03);
			content: "";
			background: $main_c;
			width: 1px;
			height: 100%;
		}
		&::after {
			position: absolute;
			left: 1.25em;
			top: -1em;
			z-index: z-index(module, part02);
			content: "";
			transform-origin: top left;
			@include transition;
			background: $hover_c;
			width: 1px;
			height: 0;
		}
	}
	&__tit {
		b {
			display: block;
			@include f-s_xxs(1, 6);
			margin-bottom: 0.3em;
			@include media-breakpoint-up(lg) {
				@include f-s_lg(0.94, 3);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(18);
			}
		}
		em {
			display: block;
			font-style: normal;
			@include f-family(font02);
			@include l-sp(0.02em);
			color: $main_c;
			@include f-w(400);
		}
	}
}

// top-separate
.top-separate01,
.top-separate02 {
	position: relative;
	&::before {
		content: "";
		display: block;
		padding-top: 36vw;
		position: relative;
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			padding-top: 36vw;
		}
		@include media-breakpoint-up(md) {
			padding-top: 40vw;
		}
		@include media-breakpoint-up(lg) {
			padding-top: 28.125vw;
		}
		@include media-breakpoint-up(xl) {
			padding-top: 420px;
		}
	}
	&__inner {
		position: absolute;
		overflow: hidden;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		picture {
			height: 100%;
		}
	}
}

// top-news
.top-news {
	padding: clamp(4rem, 7vw, 7rem) 0 clamp(4rem, 7vw, 7rem);
	&__category {
		margin-bottom: clamp(1.5rem, 2vw, 2rem);
		&__list {
			display: flex;
			flex-wrap: wrap;
			column-gap: 6px;
			row-gap: 6px;
			@include media-breakpoint-up(lg) {
				justify-content: center;
				column-gap: 10px;
				row-gap: 10px;
			}
			li {
				min-width: calc(50% - 3px);
				@include media-breakpoint-up(sm) {
					min-width: calc(33.33% - 4px);
				}
				@include media-breakpoint-up(lg) {
					min-width: calc(20% - 8px);
				}
				a {
					@include line-h(1.2);
					padding: 0.5em 2em;
					display: block;
					text-align: center;
					@include dec-none;
					background: $main_c;
					color: $white;
					@include f-w(500);
					font-size: 0.9em;
					@include transition;
					@include l-sp(0.03em);
					@include media-breakpoint-down(md) {
						font-size: 0.9em;
					}
					@include media-breakpoint-down(sm) {
						font-size: 0.84em;
					}
					&:hover {
						background: $hover_c;
					}
					&.is-all {
						background: $d-gray;
					}
				}
			}
		}
	}
	&__empty {
		.txt {
			@include f-w(500);
			@include media-breakpoint-up(md) {
				text-align: center;
			}
			@include media-breakpoint-up(lg) {
				font-size: 1.14em;
			}
		}
	}
	.btn-wrap {
		margin-top: clamp(2rem, 3vw, 3rem);
	}
}

.top-news__table {
	width: 100%;
	table {
		width: 100%;
		tr {
			border-bottom: 1px solid $m-gray;
			&:first-child {
				border-top: 1px solid $m-gray;
			}
		}
		th,
		td {
			text-align: left;
			vertical-align: top;
			@include line-h(1.5);
			@include media-breakpoint-up(lg) {
				padding: 1rem 0 1.2rem;
				@include f-em(15);
			}
			@include media-breakpoint-down(md) {
				display: block;
			}
		}
		th {
			padding-top: 1rem;
			@include f-family(font01);
			@include f-w(400);
			@include media-breakpoint-up(md) {
				font-size: 0.9em;
			}
			@include media-breakpoint-up(lg) {
				padding: 1em 2% 1em 0;
				width: 20%;
				min-width: 240px;
			}
			@include media-breakpoint-down(md) {
				display: block;
				padding: 1em 0 0.2em 0;
			}
			.is-flex {
				display: flex;
				column-gap: 0.75em;
				row-gap: 0.5em;
			}
			.is-date {
				flex-shrink: 0;
				min-width: 75px;
				span {
					@include f-family(font02);
					color: $main_c;
				}
			}
			.is-category {
				flex-shrink: 1;
				span {
					min-width: 130px;
					display: inline-block;
					@include line-h(1.2);
					padding: 0.3em 1em;
					text-align: center;
					background: $main_c;
					color: $white;
					@include f-w(500);
					font-size: 0.9em;
				}
			}
		}
		td {
			width: auto;
			@include media-breakpoint-up(lg) {
				width: 75%;
				padding: 1em 0 1em 2%;
			}
			@include media-breakpoint-down(md) {
				display: block;
				padding: 0.2em 0 1em 0;
			}
		}
	}
}

// top-access
.top-access {
	padding: clamp(4rem, 7vw, 7rem) 0 clamp(4rem, 7vw, 7rem);
	&__tit {
		margin-bottom: clamp(1.5rem, 2vw, 2rem);
		text-align: left;
		b {
			@include f-family(font02);
			@include l-sp(0.05em);
			@include f-w(400);
			display: block;
			@include line-h(1.2);
			@include f-s_xxs(2, 36);
			color: $other_c1;
			@include media-breakpoint-up(xl) {
				@include f-size(72);
			}
		}
		small {
			@include f-s_xxs(1, 4);
			@include l-sp(0.2em);
			color: $txt_c;
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			display: grid;
			column-gap: 5%;
			grid-template-columns: 45% 42.5%;
			grid-template-rows: auto 1fr;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			margin-top: 1.5rem;
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		.txt {
			.is-catch {
				@include f-w(600);
				font-size: 1.14em;
				@include line-h(1.4);
			}
		}
	}
	&__map {
		margin: 0;
		@include media-breakpoint-up(lg) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(md) {
			margin-top: clamp(1.5rem, 2vw, 2rem);
		}
		&__inner {
			border: 1px solid $m-gray;
			@include media-breakpoint-down(md) {
				@include aspect-img(60, 47);
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
	.btn-wrap {
		max-width: 360px;
		margin-top: clamp(1.5rem, 2vw, 2rem);
		@include media-breakpoint-up(lg) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
	}
}
