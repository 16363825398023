//===================================================
//	color
//===================================================
// メインカラー
$main_c:    #558DA9;//メインカラー
$sub_c:     #91B6CA;//サブカラー
$point_c:   #327CBE;//ポイントカラー
$hover_c:   #1B75A2;//ホバーカラー
$txt_c:     #303639;//テキストカラー
$link_c:    $main_c;//リンク基本カラー

// other color
$other_c1:  #1F3B4A;//アザーカラー1
$other_c2:  #5C7DB1;//アザーカラー2
$other_c3:  #92AFBF;//アザーカラー2

// backgraound
$bg_main:   #ECF5F9;//メインバックグラウンドカラーー
$bg_blue:   #EEF7FF;
$bg_blue2:   #D8EDFF;
$bg_emerald:  #E4FBFF;

// mono tone
$white:     #fff;   //ホワイト
$o-white:   #fdfdfd;//オフホワイト
$p-gray:    #f6f6f6;   //ペールグレー
$l-gray:    #E5E9EC;   //ライトグレー
$m-gray:    #C9CED2;   //ミディアムグレー
$gray:      #979DA4;   //グレー
$dl-gray:   #6F757B;   //ダルグレー
$d-gray:    #4B4D4E;   //ダークグレー
$b-gray:    #232729;   //ブラックグレー
$black:     #000;   //ブラック

// color tone
$red:       #E1091F;//レッド
$red2:      #F24949;//レッド2
$orange:    #ff8b2d;//オレンジ
$yellow:    #ffcf27;//イエロー
$y-green:   #01a63d;//イエローグリーン
$green:     #5d9e36;//グリーン
$emerald:   #1AACC2;//エメラルド
$s-blue:    #78CAF2;//スカイブルー
$l-blue:    #288FF5;//ライトブルー
$blue:      #2b74e0;//ブル-
$violet:    #425c93;//バイオレット
$purple:    #5c0c7a;//パープル
$pink:      #FF5454;//ピンク

