@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1640px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1553px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1024px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1639px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1552px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1023px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1023px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1024px) and (max-width: 1552px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1553px) and (max-width: 1639px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/common/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 4;
  /*&.slick-disabled:before {
      opacity: $slick-opacity-not-active;
  }
  &:before {
      font-family: $slick-font-family;
      font-size: 20px;
      line-height: 1;
      color: $slick-arrow-color;
      opacity: $slick-opacity-default;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }*/
}
@media (min-width: 1024px) {
  .slick-prev,
  .slick-next {
    height: 40px;
    width: 40px;
  }
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev {
  left: -15px;
}
@media (min-width: 1024px) {
  .slick-prev {
    left: -20px;
  }
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -15px;
}
@media (min-width: 1024px) {
  [dir=rtl] .slick-prev {
    right: -20px;
  }
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -15px;
}
@media (min-width: 1024px) {
  .slick-next {
    right: -20px;
  }
}
[dir=rtl] .slick-next {
  left: -15px;
  right: auto;
}
@media (min-width: 1024px) {
  [dir=rtl] .slick-next {
    left: -20px;
  }
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/*====================================================================

index.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
}
.main-view__inner {
  display: flex;
}
@media (max-width: 1023px) {
  .main-view__inner {
    flex-direction: column;
  }
}
.main-view__slider {
  position: relative;
}
@media (min-width: 1024px) {
  .main-view__slider {
    width: 60%;
  }
}
@media (min-width: 1553px) {
  .main-view__slider {
    width: 65%;
  }
}
.main-view__slider__list {
  position: relative;
}
.main-view__slider .swiper-slide {
  margin: 0;
}
.main-view__slider__img {
  position: relative;
  width: 100%;
  z-index: 1;
}
.main-view__slider__img::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.main-view__slider__img > div,
.main-view__slider__img figure,
.main-view__slider__img a,
.main-view__slider__img picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.main-view__slider .swiper-pagination {
  position: absolute;
  bottom: 10px;
  display: block;
}
.main-view__slider .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #6F757B;
  opacity: 1;
}
@media (min-width: 768px) {
  .main-view__slider .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
}
.main-view__slider .swiper-pagination-bullet-active {
  background: #558DA9;
}
.main-view__txt {
  background: url(../img/top/top_bg_tb.jpg) top left/cover no-repeat;
}
@media (min-width: 1024px) {
  .main-view__txt {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../img/top/top_bg_pc.jpg) top left/cover no-repeat;
  }
}
@media (min-width: 1553px) {
  .main-view__txt {
    width: 35%;
  }
}
.main-view__txt__inner {
  padding: clamp(2rem, 3vw, 3rem) 5%;
}
@media (min-width: 768px) {
  .main-view__txt__inner {
    padding: clamp(3rem, 4vw, 4rem) 15% clamp(3rem, 5vw, 5rem);
  }
}
@media (min-width: 1553px) {
  .main-view__txt__inner {
    padding: 1em 10%;
    max-width: 600px;
  }
}
.main-view__txt__tit {
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
}
.main-view__txt__tit b {
  display: block;
  font-family: 'Libre Bodoni', sans-serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  line-height: 1.2;
  font-size: calc(2rem + 36 * (100vw - 280px) / 1160);
  color: #1F3B4A;
}
@media (min-width: 768px) {
  .main-view__txt__tit b {
    font-size: calc(3rem + 22 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1024px) {
  .main-view__txt__tit b {
    font-size: calc(1.75rem + 16 * (100vw - 1000px) / 440);
  }
}
@media (min-width: 1553px) {
  .main-view__txt__tit b {
    font-size: 3.13rem;
  }
}
.main-view__txt__tit span {
  font-size: calc(1.25rem + 8 * (100vw - 280px) / 1160);
  letter-spacing: 0.5em;
  color: #303639;
  font-weight: 600;
}
@media (min-width: 768px) {
  .main-view__txt__tit span {
    font-size: calc(1.75rem + 8 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1024px) {
  .main-view__txt__tit span {
    font-size: calc(1.25rem + 10 * (100vw - 1000px) / 440);
  }
}
@media (min-width: 1553px) {
  .main-view__txt__tit span {
    font-size: 2.13rem;
  }
}
.main-view__txt__txt {
  line-height: 2;
}
@media (min-width: 1024px) {
  .main-view__txt__txt {
    font-size: calc(0.9rem + 2 * (100vw - 1000px) / 440);
  }
}
@media (min-width: 1553px) {
  .main-view__txt__txt {
    font-size: 1.13rem;
  }
}

/* common
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.top-works {
  padding: clamp(4rem, 7vw, 7rem) 0 clamp(4rem, 7vw, 7rem);
}
.top-works__inner .btn-wrap {
  margin-top: clamp(2rem, 4vw, 4rem);
}
.top-works__box a {
  display: block;
  height: 100%;
  text-decoration: none !important;
  color: #303639;
  transition: all 0.3s ease 0s;
}
.top-works__box a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-works__box a:hover .top-works__box__img__txt {
  bottom: 0;
}
.top-works__box a:hover .top-works__box__txt::after {
  height: 100%;
}
.top-works__box__img {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.top-works__box__img__txt {
  position: absolute;
  left: 0;
  bottom: calc(100% + 2px);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease 0s;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  font-weight: 600;
  line-height: 1.75;
}
@media (min-width: 1024px) {
  .top-works__box__img__txt {
    font-size: calc(0.8rem + 2 * (100vw - 1000px) / 440);
  }
}
@media (min-width: 1553px) {
  .top-works__box__img__txt {
    font-size: 0.88rem;
  }
}
.top-works__box__img__inner {
  margin: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
.top-works__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.top-works__box__img__inner > div,
.top-works__box__img__inner figure,
.top-works__box__img__inner a,
.top-works__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.top-works__box__txt {
  padding: 1em 5% 2em 2.25em;
  position: relative;
  z-index: 2;
}
.top-works__box__txt::before {
  position: absolute;
  left: 1.25em;
  top: -1em;
  z-index: 2;
  content: "";
  background: #558DA9;
  width: 1px;
  height: 100%;
}
.top-works__box__txt::after {
  position: absolute;
  left: 1.25em;
  top: -1em;
  z-index: 3;
  content: "";
  transform-origin: top left;
  transition: all 0.3s ease 0s;
  background: #1B75A2;
  width: 1px;
  height: 0;
}
.top-works__box__tit b {
  display: block;
  font-size: calc(1rem + 6 * (100vw - 280px) / 1160);
  margin-bottom: 0.3em;
}
@media (min-width: 1024px) {
  .top-works__box__tit b {
    font-size: calc(0.94rem + 3 * (100vw - 1000px) / 440);
  }
}
@media (min-width: 1553px) {
  .top-works__box__tit b {
    font-size: 1.13rem;
  }
}
.top-works__box__tit em {
  display: block;
  font-style: normal;
  font-family: 'Libre Bodoni', sans-serif;
  letter-spacing: 0.02em;
  color: #558DA9;
  font-weight: 400;
}

.top-separate01,
.top-separate02 {
  position: relative;
}
.top-separate01::before,
.top-separate02::before {
  content: "";
  display: block;
  padding-top: 36vw;
  position: relative;
  overflow: hidden;
}
@media (min-width: 576px) {
  .top-separate01::before,
  .top-separate02::before {
    padding-top: 36vw;
  }
}
@media (min-width: 768px) {
  .top-separate01::before,
  .top-separate02::before {
    padding-top: 40vw;
  }
}
@media (min-width: 1024px) {
  .top-separate01::before,
  .top-separate02::before {
    padding-top: 28.125vw;
  }
}
@media (min-width: 1553px) {
  .top-separate01::before,
  .top-separate02::before {
    padding-top: 420px;
  }
}
.top-separate01__inner,
.top-separate02__inner {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.top-separate01__inner picture,
.top-separate02__inner picture {
  height: 100%;
}

.top-news {
  padding: clamp(4rem, 7vw, 7rem) 0 clamp(4rem, 7vw, 7rem);
}
.top-news__category {
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
}
.top-news__category__list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 6px;
}
@media (min-width: 1024px) {
  .top-news__category__list {
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
  }
}
.top-news__category__list li {
  min-width: calc(50% - 3px);
}
@media (min-width: 576px) {
  .top-news__category__list li {
    min-width: calc(33.33% - 4px);
  }
}
@media (min-width: 1024px) {
  .top-news__category__list li {
    min-width: calc(20% - 8px);
  }
}
.top-news__category__list li a {
  line-height: 1.2;
  padding: 0.5em 2em;
  display: block;
  text-align: center;
  text-decoration: none !important;
  background: #558DA9;
  color: #fff;
  font-weight: 500;
  font-size: 0.9em;
  transition: all 0.3s ease 0s;
  letter-spacing: 0.03em;
}
@media (max-width: 1023px) {
  .top-news__category__list li a {
    font-size: 0.9em;
  }
}
@media (max-width: 767px) {
  .top-news__category__list li a {
    font-size: 0.84em;
  }
}
.top-news__category__list li a:hover {
  background: #1B75A2;
}
.top-news__category__list li a.is-all {
  background: #4B4D4E;
}
.top-news__empty .txt {
  font-weight: 500;
}
@media (min-width: 768px) {
  .top-news__empty .txt {
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .top-news__empty .txt {
    font-size: 1.14em;
  }
}
.top-news .btn-wrap {
  margin-top: clamp(2rem, 3vw, 3rem);
}

.top-news__table {
  width: 100%;
}
.top-news__table table {
  width: 100%;
}
.top-news__table table tr {
  border-bottom: 1px solid #C9CED2;
}
.top-news__table table tr:first-child {
  border-top: 1px solid #C9CED2;
}
.top-news__table table th,
.top-news__table table td {
  text-align: left;
  vertical-align: top;
  line-height: 1.5;
}
@media (min-width: 1024px) {
  .top-news__table table th,
  .top-news__table table td {
    padding: 1rem 0 1.2rem;
    font-size: 1.07em;
  }
}
@media (min-width: 1024px) and (min-width: 576px) {
  .top-news__table table th,
  .top-news__table table td {
    font-size: 1.07em;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .top-news__table table th,
  .top-news__table table td {
    font-size: 1em;
  }
}
@media (max-width: 1023px) {
  .top-news__table table th,
  .top-news__table table td {
    display: block;
  }
}
.top-news__table table th {
  padding-top: 1rem;
  font-family: 'Noto Serif JP', sans-serif;
  font-weight: 400;
}
@media (min-width: 768px) {
  .top-news__table table th {
    font-size: 0.9em;
  }
}
@media (min-width: 1024px) {
  .top-news__table table th {
    padding: 1em 2% 1em 0;
    width: 20%;
    min-width: 240px;
  }
}
@media (max-width: 1023px) {
  .top-news__table table th {
    display: block;
    padding: 1em 0 0.2em 0;
  }
}
.top-news__table table th .is-flex {
  display: flex;
  column-gap: 0.75em;
  row-gap: 0.5em;
}
.top-news__table table th .is-date {
  flex-shrink: 0;
  min-width: 75px;
}
.top-news__table table th .is-date span {
  font-family: 'Libre Bodoni', sans-serif;
  color: #558DA9;
}
.top-news__table table th .is-category {
  flex-shrink: 1;
}
.top-news__table table th .is-category span {
  min-width: 130px;
  display: inline-block;
  line-height: 1.2;
  padding: 0.3em 1em;
  text-align: center;
  background: #558DA9;
  color: #fff;
  font-weight: 500;
  font-size: 0.9em;
}
.top-news__table table td {
  width: auto;
}
@media (min-width: 1024px) {
  .top-news__table table td {
    width: 75%;
    padding: 1em 0 1em 2%;
  }
}
@media (max-width: 1023px) {
  .top-news__table table td {
    display: block;
    padding: 0.2em 0 1em 0;
  }
}

.top-access {
  padding: clamp(4rem, 7vw, 7rem) 0 clamp(4rem, 7vw, 7rem);
}
.top-access__tit {
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
  text-align: left;
}
.top-access__tit b {
  font-family: 'Libre Bodoni', sans-serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  display: block;
  line-height: 1.2;
  font-size: calc(2rem + 36 * (100vw - 280px) / 1160);
  color: #1F3B4A;
}
@media (min-width: 1553px) {
  .top-access__tit b {
    font-size: 4.5rem;
  }
}
.top-access__tit small {
  font-size: calc(1rem + 4 * (100vw - 280px) / 1160);
  letter-spacing: 0.2em;
  color: #303639;
}
@media (min-width: 1553px) {
  .top-access__tit small {
    font-size: 1.25rem;
  }
}
@media (min-width: 1024px) {
  .top-access__inner {
    display: grid;
    column-gap: 5%;
    grid-template-columns: 45% 42.5%;
    grid-template-rows: auto 1fr;
  }
}
@media (min-width: 1024px) {
  .top-access__txt {
    margin-top: 1.5rem;
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
.top-access__txt .txt .is-catch {
  font-weight: 600;
  font-size: 1.14em;
  line-height: 1.4;
}
.top-access__map {
  margin: 0;
}
@media (min-width: 1024px) {
  .top-access__map {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
@media (max-width: 1023px) {
  .top-access__map {
    margin-top: clamp(1.5rem, 2vw, 2rem);
  }
}
.top-access__map__inner {
  border: 1px solid #C9CED2;
}
@media (max-width: 1023px) {
  .top-access__map__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-access__map__inner::before {
    content: "";
    display: block;
    padding-top: 78.33%;
    position: relative;
  }
  .top-access__map__inner > div,
  .top-access__map__inner figure,
  .top-access__map__inner a,
  .top-access__map__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .top-access__map__inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.top-access .btn-wrap {
  max-width: 360px;
  margin-top: clamp(1.5rem, 2vw, 2rem);
}
@media (min-width: 1024px) {
  .top-access .btn-wrap {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}